module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/THETA.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"da92e830f3bfb74289ba25825a4a1ca4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"vXArdASJPT8YHiwBQwzH0KXeU6mdAc50","devKey":"vXArdASJPT8YHiwBQwzH0KXeU6mdAc50","trackPage":false,"trackPageImmediately":false,"trackPageOnlyIfReady":true,"trackPageOnRouteUpdate":true,"trackPageOnRouteUpdateDelay":1000,"trackPageWithTitle":false,"delayLoad":true,"delayLoadDelay":10000,"delayLoadUntilActivity":true,"delayLoadUntilActivityAdditionalDelay":13000,"manualLoad":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
