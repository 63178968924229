import React, { useState } from 'react';
import Layout from './src/components/Layout/layout';
export const isBrowser = () => typeof window !== 'undefined';

const CustomContext = React.createContext({});
export function CustomProvider(props) {
	const [user, setUser] = useState(null);
	const [token, setToken] = useState(null);
	const [loading, setLoading] = useState(true);
	return (
		<CustomContext.Provider value={{ user, setUser, loading, token, setToken }}>
			<Layout {...props}></Layout>
		</CustomContext.Provider>
	);
}

export default CustomContext;
