import * as React from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSquareXTwitter,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import LinkComponent from '../../utils/linkComponent';
import { StaticImage } from 'gatsby-plugin-image';
import { Display } from 'react-bootstrap-icons';

const FooterLinksStyled = styled.div`
	a {
		color: var(--secondary_2);
		text-decoration: none;
	}
	.thetaLogo {
		max-width: 11.5em;
	}
	.footerRow {
		padding: 1em 0em;
		margin: 1.5em 0em;
	}
	.thetaLogoCol {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left: 7px;
	}
	.sponsorsLogosCol {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
	.social_media_icons {
		font-size: 3rem;
	}
	.twitterSocials {
		padding: 0.3em;
		padding-right: 0;
		color: var(--primary);
	}
	.linkedinSocials {
		color: var(--primary);
	}

	// tablets settings
	@media (max-width: 1199.5px) {
		.sponsorsLogosCol {
			padding-right: 0;
		}
	}

	// mobile settings
	@media (max-width: 768px) {
		.thetaLogoCol {
			width: 62%;
		}
	}
`;

export default function FooterLinks(data) {
	const footerMenuData = useStaticQuery(graphql`
		query FooterMenuQuery {
			allStrapiNavigationPluginFooterMenu(sort: { order: ASC }) {
				nodes {
					items {
						path
						title
						type
					}
					order
					title
					type
					path
				}
			}
		}
	`);
	// Divide the FooterArray into 4 items length array
	var arr = footerMenuData.allStrapiNavigationPluginFooterMenu?.nodes;
	const chunk = 4;
	var footerItemArray = [];
	for (let i = 0; i < arr.length; i += chunk) {
		footerItemArray.push(arr.slice(i, i + chunk));
	}
	return (
		<FooterLinksStyled>
			<Container>
				<Row className='footerRow'>
					<Col sm={6} className='thetaLogoCol'>
						<Row>
							<Col>
								<LinkComponent to={`/`}>
									<StaticImage
										src='../../images/THETA.svg'
										className='thetaLogo'
										alt='Footer Logo'
										loading='lazy'
									/>
								</LinkComponent>
							</Col>
						</Row>
					</Col>
					<Col className='sponsorsLogosCol'>
						<Row>
							<Col>
								<div className='social_media_icons'>
									<a
										target='_blank'
										rel='https://caudit.edu.au'
										href='https://www.linkedin.com/company/3253172'
										aria-label='CAUDIT LinkedIn Page'
										className='linkedinSocials'
									>
										<FontAwesomeIcon icon={faLinkedin} />
									</a>
								</div>
							</Col>
							<Col>
								<div className='social_media_icons'>
									<a
										target='_blank'
										rel='https://caudit.edu.au'
										href='https://twitter.com/CAUDITinc'
										aria-label='CAUDIT Twitter/X Page'
										className='twitterSocials'
									>
										<FontAwesomeIcon icon={faSquareXTwitter} />
									</a>
								</div>
							</Col>
							<Col
								className='mx-3'
								style={{
									alignSelf: 'center',
								}}
							>
								<Link
									style={{
										textWrap: 'nowrap',
										textDecoration: 'underline',
										color: 'var(--link_color)',
									}}
									to='/contact-us'
								>
									Contact Us
								</Link>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</FooterLinksStyled>
	);
}
