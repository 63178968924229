import React, { useState } from 'react';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import LinkComponent from '../../utils/linkComponent';

const DropdownStyled = styled.div`
	right: -22px;
	/* width: max-content; */
	max-width: 600px;
	/* width: calc(100vw - 35rem); */
	position: absolute;
	top: 100%;
	z-index: 1;

	.dropdownBox {
		background-color: #fff;
		border-radius: 8px;
		box-shadow: 0 4px 16px rgb(46 41 51 / 8%), 0 8px 24px rgb(71 63 79 / 16%);
		color: #48434f;
		padding: 36px;
		position: relative;
		margin: 10px 0px 10px 0px;
	}

	@media screen and (min-width: 1200px) {
		.dropdownBox {
			width: max-content;
		}
	}

	.menuList {
		display: flex;
		flex-direction: column;
		gap: 15px;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.menuItem {
		gap: 16px;
		margin: 0;
		display: flex;
		flex-direction: column;
	}

	.menuItemLink {
		display: flex;
		align-items: flex-start;
		gap: 16px;
		justify-content: flex-start;
		text-decoration: none;
	}

	.menuItemLink:hover h2 {
		color: var(--link_color);
		/* text-decoration: underline; */
	}
	.menuItemLink:hover li {
		color: var(--link_color);
		/* text-decoration: underline; */
	}

	.menuItemRow1 {
		display: flex;
		align-items: flex-start;
		gap: 16px;
		justify-content: flex-start;
		text-decoration: none;
	}
	.menuItemRow1Icon {
		display: flex;
	}
	.menuItemRow1Title {
		color: #48434f;
		font-size: var(--font-size-base);
		font-weight: 600;
		margin-bottom: 0;
		margin: 0;
		white-space: nowrap;
	}
	.menuItemRow1Title::after {
		color: rgba(35, 33, 41, 0.4);
		/* content: '›'; */
		display: inline-block;
		margin-left: 4px;
	}

	.menuItemRow1Title:hover {
		color: inherit;
	}
	.menuItemRow1SubTitle {
		color: #635e69;
		margin: 0;
	}

	.childItemsList {
		-webkit-column-gap: 36px;
		column-gap: 36px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		margin-left: 56px;
		row-gap: 8px;
		list-style: none;
		/* margin: 0; */
		padding: 0;
	}
	.childItem {
		margin: 0;
		color: #635e69;
		white-space: nowrap;
		font-size: var(--font-size-sm);
	}
	.childItem:hover {
		/* color: inherit; */
		color: var(--primary);
	}
	.childItem::after {
		color: rgba(35, 33, 41, 0.4);
		/* content: '›'; */
		display: inline-block;
		margin-left: 4px;
	}

	.centerAligned {
		margin-top: 0.65em;
	}
	.reducedGap {
		gap: 5px;
	}

	// mobile settings
	@media (max-width: 1199.5px) {
		display: contents;

		.dropdownBox {
			box-shadow: 0px 0px;
			padding: 10px;
		}

		.childItemsList {
			grid-template-columns: repeat(1, 1fr);
		}

		.menuItemRow1Title {
			/* font-size: var(--font-size-base); */
		}
	}
`;
export default function Dropdown({ setExpanded, setDropdown, items }) {
	return (
		<DropdownStyled>
			<div className='dropdownBox'>
				<ul className='menuList'>
					{items.map((item) => (
						<li
							className={`menuItem ${item.subTitle ? '' : 'reducedGap'}`}
							key={item.title}
						>
							<LinkComponent
								onClick={() => {
									setDropdown(false);
									setExpanded(false);
								}}
								to={item.path}
								className='menuItemLink'
							>
								{item.sub_menu_icon ? (
									<div>
										<img width='40' src={item.sub_menu_icon}></img>
									</div>
								) : null}
								<div>
									<h2
										className={`menuItemRow1Title ${
											item.subTitle ? '' : 'centerAligned'
										}`}
									>
										{item.title}
									</h2>
									{item.subTitle && (
										<p className='menuItemRow1SubTitle'>{item.subTitle}</p>
									)}
								</div>
							</LinkComponent>
							<ul className='childItemsList'>
								{item.items?.map((subItem) => (
									<LinkComponent
										onClick={() => {
											setExpanded(false);
											setDropdown(false);
										}}
										to={subItem.path}
										className='menuItemLink'
									>
										<li className='childItem'>{subItem.title}</li>
									</LinkComponent>
								))}
							</ul>
						</li>
					))}
				</ul>
			</div>
		</DropdownStyled>
	);
}
