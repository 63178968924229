import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import moment from 'moment';
import { Container } from 'react-bootstrap';

import LinkComponent from '../../utils/linkComponent';
import { StaticImage } from 'gatsby-plugin-image';

const CopyrightStyled = styled.div`
	background-color: var(--neutral_1);
	color: var(--secondary_6);
	padding: 1em 1em;
	justify-content: center;
	font-size: 0.8em;
	text-align: center;
	/* margin-bottom: 2.7em; */

	a {
		color: var(--secondary_6) !important;
		margin-left: 0.3em;
		margin-right: 0.3em;
	}
	.sponsorsLogo {
		vertical-align: bottom;
		margin: 1.5em;
	}

	.logosContainer {
		max-width: 720px;
	}

	.acknowledgementText {
		text-align: center;
	}

	// mobile settings
`;

const Footer = () => {
	const data = useStaticQuery(graphql`
		query FooterQuery {
			siteBuildMetadata {
				id
				buildTime
			}
		}
	`);
	return (
		<CopyrightStyled>
			<Container>
				<p className='acknowledgementText'>
					THETA acknowledges the Traditional Owners of the lands where we live,
					learn and work. We pay our respects to Elders past and present and
					celebrate the stories, culture and traditions of all First Nations
					people.
				</p>
			</Container>
			<Container className='logosContainer'>
				<div className='social_media_icons'>
					<LinkComponent to={`https://www.acode.edu.au/`} target='_blank'>
						<StaticImage
							src='../../images/ACODElogo.svg'
							alt='Footer Logo'
							className='sponsorsLogo'
							loading='lazy'
							style={{ width: '6em' }}
						/>
					</LinkComponent>
					<LinkComponent to={`https://caudit.edu.au/`} target='_blank'>
						<StaticImage
							src='../../images/CAUDITlogo.svg'
							alt='Footer Logo'
							className='sponsorsLogo'
							loading='lazy'
							style={{ width: '10.5em' }}
						/>
					</LinkComponent>
					<LinkComponent to={`https://www.caul.edu.au/`} target='_blank'>
						<StaticImage
							src='../../images/CAULlogo.svg'
							alt='Footer Logo'
							className='sponsorsLogo'
							loading='lazy'
							style={{ width: '8em' }}
						/>
					</LinkComponent>
				</div>
			</Container>
			© Copyright 2024 THETA All Rights Reserved
			<LinkComponent to='/privacy-policy'>Privacy Policy</LinkComponent> -{' '}
			<LinkComponent to='/terms-and-conditions'>
				Terms and Conditions
			</LinkComponent>{' '}
			- v1.0.0 on{' '}
			{moment(data.siteBuildMetadata.buildTime).format(
				'DD MMM YYYY - HH:mm:ss'
			)}{' '}
			- Site powered by
			<a href='http://www.tektoniclabs.io' target='_blank' rel='noreferrer'>
				Tektonic Labs
			</a>
		</CopyrightStyled>
	);
};
export default Footer;
